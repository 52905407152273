import React from "react";
import Header from "./JSX/layouts/nav/Header";
import Sidebar2 from "./JSX/layouts/nav/Sidebar2";

const Home = () => {
  return (
    <>
      <div>
        <Sidebar2 />
      </div>
    </>
  );
};

export default Home;
