import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Sidebar from "../layouts/nav/Sidebar";
import Toolbar from "@mui/material/Toolbar";
import Table from "../layouts/Table/Table";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Reports from "../layouts/Reports/Reports";
import ContainerFirst from "../layouts/Containers/ContainerFirst";
import ContainerSecond from "../layouts/Containers/ContainerSecond";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      {/* <Sidebar /> */}
      <div className="container">
        <Reports />
        <div className="row ">
          <div className="col-4 bg-light m-2">
            <h5 className="text-danger p-2"> Hotel Overview</h5>
            <div className="row">
              <ContainerFirst />
            </div>
            <Box
              className="mt-4 pt-3 "
              type="Submit"
              sx={{
                display: "flex",
                flexWrap: "wrap",
                "& > :not(style)": {
                  m: 1,
                  width: 310,
                  height: 170,
                },
              }}
            >
              <Paper
                style={{ background: "#fff5f8" }}
                elevation={2}
                className=" border border-warning  rounded-3"
              >
                <h5 className="text-center  mt-4">Total Numbers</h5>
                <div className="d-flex justify-content-around align-items-center my-3 py-2">
                  {numbers.map((value, id) => (
                    <div>
                      <h6
                        className="text-center"
                        style={{ color: value.color }}
                      >
                        {value.title}
                        <br /> <span>{value.number}</span>
                      </h6>
                    </div>
                  ))}
                </div>
              </Paper>
            </Box>
          </div>
          <div className="col  m-2 " style={{ background: "#fff8dd" }}>
            <ContainerSecond />
          </div>
        </div>

        <Table
          tableButtons={tableButtons}
          tableData={tableData1}
          tableHeading={tableHeading}
          title="Recent Bookings"
          hideHeaderBtns={true}
        />
      </div>
    </>
  );
};

export default Home;

const tableHeading = [
  { id: 1, heading: "Booking Id", key: "bookingId", scope: "row" },
  { id: 2, heading: "Customer Name", key: "customerName" },
  { id: 3, heading: "Check In", key: "checkIn" },
  { id: 4, heading: "CheckOut", key: "checkOut" },
  { id: 5, heading: "Price", key: "price" },
  { id: 6, heading: "Room Type", key: "roomType" },
  {
    id: 7,
    heading: "Edit",
    key: "edit",
    icon: (
      <BorderColorOutlinedIcon
        onClick={() => alert("hi there")}
        className="text-dark"
      />
    ),
  },
  {
    id: 8,
    heading: "Delete",
    key: "delete",
    icon: <DeleteOutlineOutlinedIcon />,
  },
];

const tableData1 = [
  {
    id: "1",
    bookingId: "123456",
    customerName: "Mark Adams",
    checkIn: "1-2-2022",
    checkOut: "2-2-2022",
    price: "5000/-",
    roomType: "A/C Room",
  },
  {
    id: "2",
    bookingId: "27652447",
    customerName: "Jacob chain",
    checkIn: "2-4-2021",
    checkOut: "3-4-2021",
    price: "3000/-",
    roomType: " Non A/C Room",
  },
  {
    id: "3",
    bookingId: "36783988",
    customerName: "	Larry ley",
    checkIn: "8-12-2020",
    checkOut: "9-12-2020",
    price: "2000/-",
    roomType: "Sample",
  },
  {
    id: "4",
    bookingId: "43624454",
    customerName: "Lark Adams",
    checkIn: "1-2-2022",
    checkOut: "2-2-2022",
    price: "5000/-",
    roomType: "A/C Room",
  },
  {
    id: "5",
    bookingId: "57652447",
    customerName: "Jacob chain",
    checkIn: "2-4-2021",
    checkOut: "3-4-2021",
    price: "3000/-",
    roomType: " Non A/C Room",
  },
  {
    id: "6",
    bookingId: "66783988",
    customerName: "	Larry ley",
    checkIn: "8-12-2020",
    checkOut: "9-12-2020",
    price: "2000/-",
    roomType: "Sample",
  },
];

const numbers = [
  {
    id: 1,
    title: "Rooms",
    number: "110+",
    color: "#dc3545",
  },
  {
    id: 2,
    title: "Visitors",
    number: "310+",
    color: "#ffc107",
  },
  {
    id: 3,
    title: "Bookings",
    number: "210+",
    color: "#198754",
  },
];

const tableButtons = [
  {
    id: 1,
    title: "Add User",
    icon: (
      <Link to="/addNewForm">
        <FilterAltIcon />
      </Link>
    ),
    color: "#0dcaf0",
  },
  { id: 2, title: "Filter", icon: <DeleteIcon />, color: "#ffc107" },
  { id: 3, title: "Export", icon: <FileUploadIcon />, color: "#198754" },
];
