import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ExcelExport from "./JSX/components/ExcelExport";
import Home from "./Home";
import UserManagement from "./JSX/components/UserManagement";
import Header from "./JSX/layouts/nav/Header";
import Sidebar from "./JSX/layouts/nav/Sidebar";
import Login from "./JSX/pages/Login";
import ExcelJson from "./JSX/components/ExcelJson";

const App = () => {
  return (
    <>
      <BrowserRouter>
        {/* <ExcelJson /> */}
        {/* <ExcelExport /> */}
        <Home />
        {/* <Header /> */}
        {/* <Sidebar /> */}
        {/* <Routes>
          <Route path="/" element={<Login />} />
        </Routes> */}
      </BrowserRouter>
    </>
  );
};

export default App;
