// import React from "react";
// import * as FileSaver from "file-saver";
// import XLSX from "sheetjs-style";
// import { Button, Tooltip } from "@mui/material";

// const ExcelExport = ({ excelData, fileName }) => {
//   const fileType =
//     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
//   const fileExtension = ".xlsx";

//   const exportToExcel = async () => {
//     const fileType =
//       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
//     const fileExtension = ".xlsx";
//     const ws = XLSX.utils.json_to_sheet(excelData);
//     const excelBuffer = XLSX.write(
//       {
//         Sheets: { data: ws },
//         SheetNames: ["data"],
//       },
//       { bookType: "xlsx", type: "array" }
//     );
//     const data = new Blob([excelBuffer], { type: fileType });
//     FileSaver.saveAs(data, fileName + fileExtension);
//     // const ws = XLSX.utils.json_ti_sheet(excelData);
//     // const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
//     // const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
//     // const data = new Blob([excelBuffer], { type: fileType });
//     // FileSaver.save(data, fileName + fileExtension);
//   };

//   return (
//     <>
//       <Tooltip title="Excel Export">
//         <Button
//           variant="contained"
//           onClick={(e) => exportToExcel(fileName)}
//           color="primary"
//           style={{ cursor: "pointer", fontSize: 14 }}
//         >
//           ExcelExport
//         </Button>
//       </Tooltip>
//     </>
//   );
// };

// export default ExcelExport;

import React from "react";
import { useState, useEffect } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const ExcelExport = () => {
  const [result, setResult] = useState([]);

  const getData = () => {
    fetch("https://jsonplaceholder.typicode.com/users")
      .then((response) => response.json())
      .then((res) => setResult(res));
  };

  useEffect(() => {
    getData();
  });

  return (
    <div className="container">
      {/* <h3 className="mt-3 text-success">
        <center>Export React Table Data into EXCEL Sheet</center>
      </h3> */}
      <div className="row mt-4">
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button btn btn-success mb-3"
          table="table-to-xls"
          filename="tablexls"
          sheet="tablexls"
          buttonText="Export Data to Excel Sheet"
        />
        <table className="table" id="table-to-xls">
          <thead className="thead-dark">
            <tr>
              <th>Firstname</th>
              <th>Lastname</th>
              <th>Email</th>
              <th>Website</th>
            </tr>
          </thead>
          <tbody>
            {result.map((res) => (
              <tr>
                <td>{res.name}</td>
                <td>{res.username}</td>
                <td>{res.email}</td>
                <td>{res.website}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ExcelExport;
