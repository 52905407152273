import React from "react";

const AddNewForm = () => {
  return (
    <>
      <div className="p-4 m-4">
        <h4 className="text-danger">Add New</h4>
        <div
          className="border border-secondary-0 p-4 m-4"
          style={{ width: "100vh" }}
        >
          <form class="row g-3">
            {formData?.map((value, id) => (
              <div class="col-md-6">
                <label class="form-label">{value?.title}</label>
                <input type={value?.type} class="form-control rounded-0" />
              </div>
            ))}
            {selectData?.map((value, id) => (
              <div class="col-md-6">
                <label for="inputState" class="form-label">
                  {value?.title}
                </label>
                <select class="form-select rounded-0" type="number">
                  <option selected>{value?.selected}</option>
                  <option>{value?.option1}</option>
                  <option>{value?.option2}</option>
                  <option>{value?.option3}</option>
                  <option>{value?.option4}</option>
                  <option>{value?.option5}</option>
                </select>
              </div>
            ))}
            <div class="col-12">
              <button
                type="submit"
                class="btn btn-dark rounded-0 d-flex ms-auto m-1 py-2 px-4"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddNewForm;

const formData = [
  {
    id: 1,
    inputType: "text",
    title: "Name",
  },
  {
    id: 2,
    inputType: "number",
    title: "Customer Id",
  },
  {
    id: 3,
    inputType: "email",
    title: "Email Id",
  },
  {
    id: 4,
    inputType: "number",
    title: "Contact Number",
  },
];

const selectData = [
  {
    id: 5,
    inputType: "text",
    title: "Purpose",
    selected: "Business Purpose",
    option1: "Family Visit",
    option2: "Tourism",
    option3: "Other",
  },
  {
    id: 6,
    inputType: "number",
    title: "Select Number of Members",
    selected: "1 adult",
    option1: "1 adult + 1 child",
    option2: "1 adult + 2 children",
    option3: "2 adults",
    option4: "2 adults + 1 child",
    option5: "2 adults + 2 children",
  },
  {
    id: 7,
    inputType: "text",
    title: "Select Room Type",
    selected: "A/C Room",
    option1: "Non A/C Room",
    option2: "Single Bed A/C Room",
    option3: "Single Bed Non A/C Room",
    option4: "Double Bed A/C Room",
    option5: "Double Bed Non A/C Room",
  },
];
