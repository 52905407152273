import * as React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MenuItem from "@mui/material/MenuItem";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";

// Added icons
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import CasesOutlinedIcon from "@mui/icons-material/CasesOutlined";
import AutoAwesomeMotionOutlinedIcon from "@mui/icons-material/AutoAwesomeMotionOutlined";
import MoneyOutlinedIcon from "@mui/icons-material/MoneyOutlined";
import Home from "../../components/Home";
import UserManagement from "../../components/UserManagement";
import RoomBookings from "../../components/RoomBookings";
import RoomManagement from "../../components/RoomManagement";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import ReviewsOutlinedIcon from "@mui/icons-material/ReviewsOutlined";
import CategoryManagement from "../../components/CategoryManagement";
import VoucherManagement from "../../components/VoucherManagement";
import BookingReports from "../../components/BookingReports";
import ReviewManagement from "../../components/ReviewManagement";
import Setting from "../../components/Setting";
import AddNewForm from "../Form/AddNewForm";
import Login from "../../pages/Login";
import Login1 from "../../pages/Login1";
import { Link } from "react-router-dom";
import ExcelExport from "../../components/ExcelExport";

const drawerWidth = 260;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Sidebar2 = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [age, setAge] = React.useState("");
  // const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    setAuth(event.target.checked);
    setAge(event.target.value);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        className="d-flex justify-content-end text-dark rounded-xl "
        style={{ background: "white" }}
        s
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h5"
            className="text-danger"
            noWrap
            component="div"
            sx={{ flexGrow: 1 }}
          >
            Vibha Suitess
          </Typography>
          {auth && (
            <div>
              <IconButton
                size="large"
                aria-label="show 4 new mails"
                color="inherit"
              >
                <Badge badgeContent={4} color="error">
                  <MailIcon />
                </Badge>
              </IconButton>
              <IconButton
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
              >
                <Badge badgeContent={17} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>

        <Divider />
        <List>
          {[
            { title: "Dashboard", link: "/" },
            { title: "User Management", link: "/user" },
            { title: "Room Management", link: "/roomManagement" },
            { title: "Room Bookings", link: "/room" },
            { title: "Category Management", link: "/category" },
            { title: "Voucher Management", link: "/voucher" },
            { title: "Booking Reports", link: "/bookingReports" },
            { title: "Booking Reviews", link: "/reviews" },
            { title: "Settings", link: "/setting" },
            { title: "Logout", link: "/" },
          ].map((text, index) => (
            <Link to={text.link} style={{ textDecoration: "none" }}>
              <ListItem
                key={text}
                disablePadding
                className=" my-1 text-dark"
                variant="h6"
              >
                <ListItemButton className=" rounded-pill mx-1">
                  <ListItemIcon className="text-danger">
                    {index === 0 && <HomeOutlinedIcon />}
                    {index === 1 && <PeopleOutlineOutlinedIcon />}
                    {index === 2 && <ViewInArOutlinedIcon />}
                    {index === 3 && <CasesOutlinedIcon />}
                    {index === 4 && <AutoAwesomeMotionOutlinedIcon />}
                    {index === 5 && <MoneyOutlinedIcon />}
                    {index === 6 && <ReportProblemOutlinedIcon />}
                    {index === 7 && <ReviewsOutlinedIcon />}
                    {index === 8 && <SettingsSuggestIcon />}
                    {index === 9 && <LogoutIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text.title} />
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
          {/*               
            <ListItem key={text} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem> */}
        </List>
        <Divider />
        {/* <List>
          {["All mail", "Trash", "Spam"].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/user" element={<UserManagement />} />
          <Route path="/room" element={<RoomBookings />} />
          <Route path="/roomManagement" element={<RoomManagement />} />
          <Route path="/category" element={<CategoryManagement />} />
          <Route path="/voucher" element={<VoucherManagement />} />
          <Route path="/bookingReports" element={<BookingReports />} />
          <Route path="/reviews" element={<ReviewManagement />} />
          <Route path="/setting" element={<Setting />} />
          {/* <Route path="/login" element={<Login1 />} /> */}
          <Route path="/addNewForm" element={<AddNewForm />} />
          <Route path="/excelExport" element={<ExcelExport />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default Sidebar2;
