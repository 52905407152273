import React from "react";
import Table from "../layouts/Table/Table";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import AddIcon from "@mui/icons-material/Add";
import { exportToExcel } from "react-json-to-excel";

const RoomManagement = () => {
  return (
    <div>
      <Table
        tableButtons={tableButtons}
        tableHeading={tableManagementHeadingData}
        tableData={roomManagementBookingData}
        title="Room Management"
      />
    </div>
  );
};

export default RoomManagement;

const tableButtons = [
  {
    id: 1,
    title: "Add New",
    icon: <AddIcon />,
    color: "#0dcaf0",
    link: "/addNewForm",
  },
  { id: 2, title: "Filter", icon: <FilterAltIcon />, color: "#ffc107" },
  {
    id: 3,
    title: "Export",
    icon: (
      <FileUploadIcon
        onClick={() =>
          exportToExcel(roomManagementBookingData, "downloadfilename")
        }
      />
    ),
    color: "#198754",
  },
];

const tableManagementHeadingData = [
  {
    id: 1,
    heading: "Booking Id",
    key: "bookingId",
  },
  {
    id: 2,
    heading: "Customer Name ",
    key: "customerName",
  },
  {
    id: 3,
    heading: "Check In",
    key: "checkIn",
  },
  {
    id: 4,
    heading: "Check Out",
    key: "checkOut",
  },
  {
    id: 5,
    heading: "Price",
    key: "price",
  },
  {
    id: 6,
    heading: "Room Type",
    key: "roomType",
  },
  {
    id: 6,
    heading: "Status",
    key: "status",
  },
];

const roomManagementBookingData = [
  {
    id: "1",
    bookingId: "13624454",
    customerName: "Mark",
    checkIn: "1-2-2022",
    checkOut: "2-2-2022",
    price: "5000/-",
    roomType: "A/C Room",
    status: "Pending",
  },
  {
    id: "2",
    bookingId: "27652447",
    customerName: "Jacob chain",
    checkIn: "2-4-2021",
    checkOut: "3-4-2021",
    price: "3000/-",
    roomType: " Non A/C Room",
    status: "Approved",
  },
  {
    id: "3",
    bookingId: "36783988",
    customerName: "	Larry ley",
    checkIn: "8-12-2020",
    checkOut: "9-12-2020",
    price: "2000/-",
    roomType: "Sample",
    status: "Canceled",
  },
  {
    id: "4",
    bookingId: "43624454",
    customerName: "Lark Adams",
    checkIn: "1-2-2022",
    checkOut: "2-2-2022",
    price: "5000/-",
    roomType: "A/C Room",
    status: "Pending",
  },
  {
    id: "5",
    bookingId: "57652447",
    customerName: "Jacob chain",
    checkIn: "2-4-2021",
    checkOut: "3-4-2021",
    price: "3000/-",
    roomType: " Non A/C Room",
    status: "Approved",
  },
  {
    id: "6",
    bookingId: "66783988",
    customerName: "	Larry ley",
    checkIn: "8-12-2020",
    checkOut: "9-12-2020",
    price: "2000/-",
    roomType: "Sample",
    status: "Canceled",
  },
];

const roomManagementFormData = [
  {
    id: 1,
    inputType: "text",
    title: "Name",
  },
  {
    id: 2,
    inputType: "number",
    title: "Customer Id",
  },
  {
    id: 3,
    inputType: "email",
    title: "Email Id",
  },
  {
    id: 4,
    inputType: "number",
    title: "Contact Number",
  },
];

const selectData = [
  {
    id: 5,
    inputType: "text",
    title: "Purpose",
    selected: "Business Purpose",
    option1: "Family Visit",
    option2: "Tourism",
    option3: "Other",
  },
  {
    id: 6,
    inputType: "number",
    title: "Select Number of Members",
    selected: "1 adult",
    option1: "1 adult + 1 child",
    option2: "1 adult + 2 children",
    option3: "2 adults",
    option4: "2 adults + 1 child",
    option5: "2 adults + 2 children",
  },
  {
    id: 7,
    inputType: "text",
    title: "Select Room Type",
    selected: "A/C Room",
    option1: "Non A/C Room",
    option2: "Single Bed A/C Room",
    option3: "Single Bed Non A/C Room",
    option4: "Double Bed A/C Room",
    option5: "Double Bed Non A/C Room",
  },
];
